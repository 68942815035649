const logo = document.querySelector('.nav__logo > img');
const btns = document.querySelectorAll('.nav__btns > li > a');

if (window.innerWidth <= 992) {
  logo.src = '/wp-content/themes/allia-bronzes/dist/img/logo.svg';

  btns.forEach((btn) => {
    btn.textContent = '';
    const label = btn.getAttribute('data-label');

    const icon = document.createElement('i');

    if (label === 'panier') {
      icon.className = 'icon-cart';
    }

    if (label === 'compte' || label === 'connexion') {
      icon.className = 'icon-user';
    }

    btn.appendChild(icon);
  });
}
